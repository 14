import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import axios from "axios";
import HttpsRedirect from "react-https-redirect";

import "./index.scss";
import * as serviceWorker from "./main/serviceWorker";
import App from "./main/App";

if (
  process.env.REACT_ENV === "production" ||
  process.env.REACT_ENV === "staging"
)
  Sentry.init({
    dsn:
      "https://affe0aef1f7e4734b31c817df1eeec4c@o1216711.ingest.sentry.io/4503930213433344",
    integrations: [new BrowserTracing()],
    environment: process.env.REACT_ENV,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });

const redirectToHttps =
  process.env.REACT_APP_HOST === "www.datascrap.io" ? true : false;

axios.defaults.withCredentials = true;
axios.defaults.baseURL =
  process.env.REACT_APP_API_BASE_URL || "http://api.datascrap.dev-fr/";

if (window.location.host === "localhost:3000") {
  window.location = "http://www.datascrap.dev-fr";
}

ReactDOM.render(
  <React.StrictMode>
    <HttpsRedirect disabled={!redirectToHttps}>
      <App />
    </HttpsRedirect>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
