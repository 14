import React, { useEffect } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import ReactGA from "react-ga";
import { createBrowserHistory } from "history";

import Loadable from "react-loadable";
import Loading from "./Loading";

ReactGA.initialize("UA-167737058-1");

const buildApp = (appFolder) =>
  Loadable({
    loader: () => import(`../${appFolder}/App.js`),
    loading: Loading,
  });

const setAppFromSubdomain = () => {
  const subdomain = window.location.host.split(".")[0];

  if (subdomain === "admin" || subdomain === "admin-staging") {
    return buildApp("admin");
  } else if (subdomain === "app" || subdomain === "app-staging") {
    return buildApp("dashboard");
  } else {
    return buildApp("public");
  }
};

export default () => {
  const history = createBrowserHistory();

  history.listen((location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  });
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [history]);

  return (
    <Router history={history}>
      <Switch>
        <Route path="/" component={setAppFromSubdomain()} />
        <Redirect from="*" to="/" />
      </Switch>
    </Router>
  );
};
